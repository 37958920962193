import React from "react";
import {getLink, getRoutes} from "../../shared/routes";
import { navigate } from "gatsby";

const RedirectTo = (props) => {
  const { url = getLink([getRoutes().home]) } = props;
  if (typeof window !== 'undefined') {
    navigate(url)
  }
  return null;
};

export default RedirectTo;
